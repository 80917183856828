import React from "react";
import BlogFeed from "../../components/blog-feed";
import Layout from "../../components/layout";
import AWSCheatSheet from "../../docs/729_Customize_Zendesk_Workflow_A6b.pdf";


const Index = () => {
  return (
    <Layout>
      <section className="my-6">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mb-4"
                height={112}
                width={112}
                viewBox="0 0 64 64"
              >
                <g
                  fill="none"
                  stroke="#00833e"
                  stroke-linecap="square"
                  stroke-miterlimit="10"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="butt"
                    d="m47.409 22.323-7.637-7.629a2.733 2.733 0 0 0-3.919.055 2.733 2.733 0 0 0 .054 3.81l8.363 8.363m-7.209-7.208L32.8 15.481a2.733 2.733 0 0 0-3.92.056 2.733 2.733 0 0 0 .055 3.809M60.787 42.32a15.2 15.2 0 0 0 .43-10.762c-1.6-4.787-3.877-10.187-4.968-15.091a3.008 3.008 0 1 0-5.892 1.208A60.039 60.039 0 0 1 51 26.718"
                  />
                  <path d="M21.766 37.366a2.992 2.992 0 0 0-4.156-.031h0a2.966 2.966 0 0 0-.06 4.255l16.765 16.758a12.474 12.474 0 0 0 17.639 0h0a16.565 16.565 0 0 0 4.024-16.958c-1.738-5.2-4.209-11.058-5.394-16.382a3.265 3.265 0 1 0-6.4 1.312c.03.243.693 5.189 1.409 8.884a.3.3 0 0 1-.51.269L32.7 23.084a2.967 2.967 0 0 0-4.255.06h0a2.968 2.968 0 0 0 .055 4.136l10.479 10.476" />
                  <path d="M21.769 28.977a2.967 2.967 0 0 0-4.135-.058h0a2.967 2.967 0 0 0-.061 4.255l12.994 12.994M28.75 27.527l-3.62-3.589a2.967 2.967 0 0 0-4.255.062h0a2.967 2.967 0 0 0 .059 4.135l13.833 13.833" />
                  <path
                    stroke="#76bc21"
                    d="m9.5 9.394-1.656 1.879a1.251 1.251 0 0 0 .256 1.875L15.5 18a1.252 1.252 0 0 0 1.639-1.86l-5.747-6.73A1.252 1.252 0 0 0 9.5 9.394ZM4.32 20.321a1.254 1.254 0 0 0-1.684.861l-.6 2.432a1.251 1.251 0 0 0 1.09 1.546l8.809.882a1.252 1.252 0 0 0 .593-2.407ZM22.052 2.147a1.248 1.248 0 0 0-1.038-.063l-2.337.9a1.252 1.252 0 0 0-.643 1.778l4.318 7.1209a1.252 1.252 0 0 0 2.313-.891l-1.981-8.629a1.248 1.248 0 0 0-.632-.824Z"
                  />
                </g>
              </svg>
              <h2>Thank you!</h2>
              <h3>You're on your way to a more efficient Zendesk instance!</h3>
              <a
                className="btn btn-primary-light"
                target="_blank"
                rel="noreferrer"
                href={AWSCheatSheet}
              >
                DOWNLOAD ZENDESK WORKFLOW GUIDE
              </a>
            </div>
          </div>
        </div>
      </section>
      <BlogFeed title="Want to learn more?" containerClassname="py-6" />
    </Layout>
  );
};

export default Index;
